/**
 * 获取文件扩展名
 * @param fileName 文件名称，可以是http的形式(仅限于我们系统上传的文件)
 */
export const getFileExt = (fileName: string, upperCase?: boolean) => {
  const index = fileName.lastIndexOf('.')
  const ext = fileName.slice(index + 1) // 获取后缀

  return upperCase ? ext.toUpperCase() : ext
}

// TODO: 移到contants公共包
const FILE_ICON_MAPS = {
  '.rar': 'fc-yasuowenjian-new',
  '.zip': 'fc-yasuowenjian-new',
  '.tar': 'fc-yasuowenjian-new',
  '.gz': 'fc-yasuowenjian-new',
  '.bz2': 'fc-yasuowenjian-new',
  '.doc': 'fc-word-new',
  '.docx': 'fc-word-new',
  '.xlsx': 'fc-excle-new',
  '.xls': 'fc-excle-new',
  '.pdf': 'fc-pdf-new',
  '.mp3': 'fc-music-new',
  '.ppt': 'fc-ppt-new',
  '.pptx': 'fc-ppt-new',
  '.mp4': 'fc-shipin-new',
  '.avi': 'fc-shipin-new',
  '.rmvb': 'fc-shipin-new',
  '.wmv': 'fc-shipin-new',
  '.flv': 'fc-shipin-new',
  '.swf': 'fc-shipin-new',
  '.rm': 'fc-shipin-new',
  '.qsv': 'fc-shipin-new',
  '.txt': 'fc-txt-new',
  '.jpg': 'fc-picture-new',
  '.png': 'fc-picture-new',
  '.jpeg': 'fc-picture-new',
  '.gif': 'fc-picture-new',
  '.ico': 'fc-picture-new',
  '.bmp': 'fc-picture-new',
  '.unknow': 'fc-weizhileixing-new',
}
/**
 * 根据文件后缀名获取图标
 */
export const getFileIcon = <T extends keyof typeof FILE_ICON_MAPS>(extend: T) => {
  return FILE_ICON_MAPS[extend]
}

/**
 * 通过blob下载文件
 * @param  fileName 文件名称
 * @param  data 二进制流
 * @param  blobOptions blobOptions
 * @returns
 */
export const dowloadBlobFile = (fileName: string, data: BlobPart, blobOptions = {}) => {
  if (!data) {
    return
  }
  const blob = new Blob([data], blobOptions)
  const elink = document.createElement('a')
  const navigator: any = {}
  if ('download' in elink) {
    // 非IE下载
    elink.download = fileName
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href) // 释放URL 对象
    document.body.removeChild(elink)
  } else {
    // IE10+下载
    navigator.msSaveBlob(blob, fileName)
  }
}

/**
 * 为防止 后台返回错误的code无法拦截 所以需要将blob转换为json 解析
 */
export const blobToText = (blob: BlobPart) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsText(new Blob([blob]), 'utf-8')
    fileReader.onload = (res: any) => {
      try {
        const { result } = res.target // 得到字符串
        const data = JSON.parse(result)

        if (data) {
          resolve(data)
        } else {
          reject()
        }
      } catch (e) {
        // TODO handle the exception
        reject(e)
      }
    }
  })
}

// 格式化文件大小单位
export function getFileSizeWithUnit(fileSize: number, idx = 0): string {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB']
  if (fileSize < 1024 || idx === units.length - 1) {
    return fileSize.toFixed(2) + units[idx]
  }
  return getFileSizeWithUnit(fileSize / 1024, ++idx)
}

// 按二进制格式读取文件
export const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve) => {
    let reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onload = (ev) => {
      resolve(ev.target?.result as ArrayBuffer)
    }
  })
}
