import { ACTION_MAP } from '../../constants'
import { ActionType, ExtarctKeyValuePairsFromArrObj } from '@common/types'

export const getUrl = (path: string) => {
	let currentPath = path
	if (currentPath[0] === '/') {
		currentPath = path.slice(1)
	}
	return new URL(currentPath, import.meta.url).href
}

export const getAssetUrl = (path: string) => {
	let currentPath = path
	if (currentPath[0] === '/') {
		currentPath = path.slice(1)
	}
	return new URL(`/src/assets/${currentPath}`, import.meta.url).href
}

export const getRootFontSize = () => {
	return document.documentElement.style.fontSize || '16px'
}

/**
 * 根据动作类型获取描述
 */
export const getActionDesc = (actionType?: ActionType) =>
	actionType ? ACTION_MAP[actionType]?.desc : ''
