import { BASE_DATE_FORMAT, INVALID_DATE } from '@common/constants'
import moment, { Moment, MomentInput } from 'moment'

/**
 * 将字符串转换为日期, 如果已经是日期则原样返回, 支持数组类型的字符串
 */
export const strToMoment = (value: MomentInput) => {
  if (Array.isArray(value)) {
    return value.reduce<Moment[]>((acc, item) => {
      acc.push(strToMoment(item) as Moment)
      return acc
    }, [])
  }
  return moment.isMoment(value) ? value : moment(value)
}

/**
 * 将日期转换为字符串, 如果已经是字符串则原样返回, 支持数组类型的日期
 */
export const momentToStr = (value: Moment | [Moment, Moment], format?: string) => {
  if (Array.isArray(value)) {
    return value.reduce<string[]>((acc, item) => {
      acc.push(momentToStr(item, format) as string)
      return acc
    }, [])
  }
  return moment.isMoment(value) ? moment(value).format(format || BASE_DATE_FORMAT) : INVALID_DATE
}

// /**
//  * vue 将日期转换为字符串, 如果已经是字符串则原样返回, 支持数组类型的日期
//  */
export const momentToStrVue = (value:string, format?: string) => {
  return moment(value).format(format || BASE_DATE_FORMAT)
}