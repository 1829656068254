import { ValueType } from '@common/types'
import { getRootFontSize } from '../get'
import { isRem } from '../is'
interface FormatAliOssUrlOptions {
	width?: ValueType
	height?: ValueType
	enlargeScale?: number // 基于传入的width和height的放大倍率,倍率越高则图片越清晰,在放大的情况下越不容易失真
	isVideo?: boolean // 是否视频截帧
	compressionRatio?: number // 质量压缩比例
}

/**
 * 可通过oss参数转换格式的图片后缀
 */
const TRANSFORMABLE_IMG_SUFFIXS = ['jpg', 'png', 'webp', 'bmp', 'gif', 'tiff', 'jpeg', 'exif', 'apng']

/**
 * 给ali-oss的url加上处理参数
 */
export const formatAliOssUrl = (soucreUrl: string, options?: FormatAliOssUrlOptions) => {
	let [currentUrl, query = ''] = soucreUrl.split('?')
	if (query) {
		query += '&'
	}
	const enlargeScale = options?.enlargeScale || 3
	const { width, height, isVideo, compressionRatio = 100 } = options || {}
	const currentSuffix = currentUrl.split('.')?.pop()?.toLocaleLowerCase()
	const widthIsRem = width && isRem(width.toString())
	const heightIsRem = height && isRem(height.toString())
	let currentWdith = Math.round(Number(width?.toString().replace(/[a-zA-Z]/g, ''))) * enlargeScale
	let currentHeight = Math.round(Number(height?.toString().replace(/[a-zA-Z]/g, ''))) * enlargeScale
	if (widthIsRem) {
		currentWdith *= Number(getRootFontSize().replace(/[a-zA-Z]/g, ''))
	}
	if (heightIsRem) {
		currentHeight *= Number(getRootFontSize().replace(/[a-zA-Z]/g, ''))
	}
	if (isVideo) {
		return currentUrl + `?${query}x-oss-process=video/snapshot,t_0,f_jpg,w_${currentWdith},h_${currentHeight},m_fast`
	}
	// 如果图片不是jpg并且支持转换格式时则先转成jpg, 图片处理包含缩放操作时，建议将格式转换参数放到处理参数的最后。
	if (TRANSFORMABLE_IMG_SUFFIXS.some((suffix) => suffix === currentSuffix)) {
		// 缩放参数
		let resizeParam = '/resize,'
		// 图片格式转换参数
		let formatParam = ''
		// 压缩参数
		let compressParam = `/quality,q_${compressionRatio}`
		if (currentWdith) {
			resizeParam += `w_${currentWdith},`
		}
		if (currentHeight) {
			resizeParam += `h_${currentHeight},`
		}
		if (!currentWdith && !currentHeight) {
			// 如果图片宽高未知则给默认宽度并按比例裁剪
			resizeParam += `w_${1000},`
		}
		if (currentSuffix !== 'jpg') {
			formatParam = '/format,jpg'
		}
		return currentUrl + `?${query}x-oss-process=image${compressParam + resizeParam + 'm_mfit' + formatParam}`
	}
	return soucreUrl
}
